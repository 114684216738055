import React from "react";
import { useState, useEffect } from "react";

function useLectures() {
  const [lecturesData, setLecturesData] = useState([]);
  const [lecturesDataLoaded, setLecturesDataLoaded] = useState(false);

  useEffect(() => {
    fetch("/retrieve_lectures_data")
      //fetch("./lecturesData.json")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setLecturesData(data);
        setLecturesDataLoaded(true);
      })
      .catch((e) => {
        console.log(e.message);
      });
  }, []);

  return [lecturesData, lecturesDataLoaded];
}

export default useLectures;
