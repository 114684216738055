import Spinner from "react-bootstrap/Spinner";

function TextSpinner({ text }) {
  return (
    <div className="text-spinner">
      <Spinner animation="border" />
      <h5>{text}</h5>
    </div>
  );
}

export default TextSpinner;
