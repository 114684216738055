import React from "react";
import "./App.css";
import "./components/Lectures";
import Lectures from "./components/Lectures";
import Header from "./components/Header";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
import { Routes, Route, BrowserRouter } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <br />
      <Routes>
        <Route exact path="/" element={<Lectures />} />
        <Route path="/שיעורים" element={<Lectures />} />
        <Route path="/צורקשר" element={<ContactUs />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
