import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import LectureUnitModal from "./LectureUnitModal";

function LectureUnitCard({
  lectureUnitPath,
  lectureUnitName,
  lectureUnitData,
}) {
  const cardText =
    lectureUnitData.lecturers.length >= 1
      ? lectureUnitData.lecturers.length === 1
        ? "נמצא מגיד שיעור אחד"
        : `נמצאו ${lectureUnitData.lecturers.length} מגידי שיעור`
      : "לא נמצאו מגידי שיעור";

  return (
    <>
      <Card
        border="dark"
        style={{ width: "16rem" }}
        className="mb-2 text-center"
      >
        <Card.Header className="lecture-unit-card-header">
          {lectureUnitData.override_parent_name ? "" : `${lectureUnitName} `}
          {lectureUnitData.name}
        </Card.Header>
        <Card.Body className="d-flex flex-column">
          <Card.Title className="card-title-description">
            {lectureUnitData.description}
          </Card.Title>
          <Card.Text className="fst-italic text-muted">{cardText}</Card.Text>
          <LectureUnitModal
            lectureUnitPath={lectureUnitPath}
            lectureUnitName={lectureUnitName}
            lectureUnitData={lectureUnitData}
          />
        </Card.Body>
      </Card>
    </>
  );
}
export default LectureUnitCard;
