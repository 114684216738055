import React from "react";
import { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import { Col, Container } from "react-bootstrap";
import Filters from "./Filters";
import useLectures from "../hooks/useLectures";
import LectureUnitCard from "./LectureUnitCard";
import TextSpinner from "./TextSpinner";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import { useMemo } from "react";

function Lectures() {
  // holds all lectures data in json format
  const [lecturesData, lecturesDataLoaded] = useLectures();

  // filter bsed on URL
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // filters and display state
  const [bookFilters, bookFiltersParseSuccess, displayedLecturesData] = useMemo(
    function () {
      const emptyDisplayedLecturesData = {
        path: [],
        unit_name: "",
        units: [],
      };
      if (lecturesDataLoaded) {
        let i = 0;
        let resultBookFilters = [];
        let reachedUnits = false;
        let currentLecturesData = {
          book_name: "ספר",
          books: lecturesData,
        };
        let unitName = "";
        let path = [];
        let units = [];
        const searchParamsLength = Array.from(searchParams).length;
        for (const [key, value] of searchParams.entries()) {
          if (i === 0 && key === currentLecturesData.book_name) {
            currentLecturesData = currentLecturesData.books;
            path.push({
              name: key,
              value: value,
            });
          } else if (
            currentLecturesData.hasOwnProperty("parts") &&
            currentLecturesData.part_name === key
          ) {
            currentLecturesData = currentLecturesData.parts;
            path.push({
              name: key,
              value: value,
            });
          } else if (
            i === searchParamsLength - 1 &&
            currentLecturesData.hasOwnProperty("units") &&
            currentLecturesData.unit_name === key
          ) {
            unitName = currentLecturesData.unit_name;
            reachedUnits = true;
            currentLecturesData = currentLecturesData.units;
          } else {
            // we got bad input from URL
            return [[], false, emptyDisplayedLecturesData];
          }

          // update filters
          resultBookFilters.push({
            id: i,
            name: key,
            selectedValue: value,
            values: ["", ...currentLecturesData.map((e) => e.name)],
          });
          currentLecturesData = currentLecturesData.find(
            (e) => e.name === value
          );
          // verify we found the value
          if (currentLecturesData === undefined) {
            return [[], false, emptyDisplayedLecturesData];
          }

          i += 1;
        }

        // add last filter if needed
        if (!reachedUnits) {
          let key = null;
          if (currentLecturesData.hasOwnProperty("books")) {
            key = currentLecturesData.book_name;
            currentLecturesData = currentLecturesData.books;
          } else if (currentLecturesData.hasOwnProperty("parts")) {
            key = currentLecturesData.part_name;
            currentLecturesData = currentLecturesData.parts;
          } else if (currentLecturesData.hasOwnProperty("units")) {
            unitName = currentLecturesData.unit_name;
            key = currentLecturesData.unit_name;
            units = [...currentLecturesData.units];
            currentLecturesData = currentLecturesData.units;
          }
          resultBookFilters.push({
            id: i,
            name: key,
            selectedValue: "",
            values: ["", ...currentLecturesData.map((e) => e.name)],
          });
        } else {
          units = [{ ...currentLecturesData }];
        }

        return [
          resultBookFilters,
          true,
          {
            path: path,
            unit_name: unitName,
            units: units,
          },
        ];
      } else {
        return [[], false, emptyDisplayedLecturesData];
      }
    },
    [lecturesDataLoaded, searchParams]
  );

  useEffect(() => {
    if (lecturesDataLoaded && !bookFiltersParseSuccess) {
      navigate({});
    }
  }, [lecturesDataLoaded, bookFiltersParseSuccess]);

  function handleBookFilterChange(index, newValue) {
    const newBooksFilters = bookFilters.slice(0, index + 1);
    newBooksFilters[index] = {
      ...newBooksFilters[index],
      selectedValue: newValue,
    };

    var newNavigation = newBooksFilters.map((filter) => ({
      [filter.name]: filter.selectedValue,
    }));
    if (newValue === "") {
      newNavigation.pop();
    }
    newNavigation = Object.assign({}, ...newNavigation);
    navigate({
      search: `?${createSearchParams(newNavigation)}`,
    });
  }

  return (
    <main>
      <div className="site-upper-body">
        <h4>ברוכים הבאים</h4>
        <p>
          האתר מכיל שיעורים בנושאים הלכתיים שונים ומגוונים, ונמצא בשלבי גדילה
          והכנסה של שיעורים רבים נוספים. <br />
          השיעורים מסודרים באופן היררכי לפי ספר (שולחן ערוך, החפץ חיים וכו'),
          באופן שמאפשר לימוד על הסדר.
        </p>
        <p>נא לבחור את פרטי השיעור המבוקש:</p>
      </div>
      {lecturesDataLoaded ? (
        <Filters
          bookFilters={bookFilters}
          onBookFilterChange={handleBookFilterChange}
        />
      ) : (
        <TextSpinner text={"השיעורים בטעינה"} />
      )}
      <hr style={{ border: "1px dashed" }} />
      {
        <Container>
          <Row>
            {displayedLecturesData.units.map((unitData, index) => (
              <Col
                xs={12}
                sm={6}
                md={4}
                lg={3}
                className="d-flex justify-content-center"
                key={index}
              >
                <LectureUnitCard
                  lectureUnitPath={displayedLecturesData.path}
                  lectureUnitName={displayedLecturesData.unit_name}
                  lectureUnitData={unitData}
                />
              </Col>
            ))}
          </Row>
        </Container>
      }
    </main>
  );
}

export default Lectures;
