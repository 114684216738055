import React from "react";

const LectureVideoView = ({ youtubeEmbedId }) => (
  <iframe
    className="youtube-video-iframe"
    src={`https://www.youtube.com/embed/${youtubeEmbedId}`}
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write;
        encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen 
    title="Embedded youtube"
  />
);

export default LectureVideoView;
