import React from "react";

function ContactUs() {
  return (
    <div className="site-upper-body">
      <h4>יצירת קשר דרך האימייל</h4>
      <p>
        לפניות בנושא שיעורים ובנושאים נוספים, ניתן לפנות אלינו בכתובת המייל
        halaha.web@gmail.com.
      </p>
    </div>
  );
}

export default ContactUs;
