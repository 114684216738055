import React from "react";

function BookFilterUnit(props) {
  return (
    <div>
      {props.name}:{" "}
      <select
        value={props.selectedValue}
        onChange={(e) => props.onFilterChange(e.target.value)}
        className="book-filter-unit"
      >
        {props.values.map((bookFilterValue, index) => (
          <option key={index} value={bookFilterValue}>
            {bookFilterValue}
          </option>
        ))}
      </select>
    </div>
  );
}

export default BookFilterUnit;
