import { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import LecturerUnit from "./LecturerUnit";
import LectureVideoView from "./LectureVideoView";
import { Container } from "react-bootstrap";
import Stack from "react-bootstrap/Stack";

function LectureUnitModal({
  lectureUnitPath,
  lectureUnitName,
  lectureUnitData,
}) {
  const [show, setShow] = useState(false);

  const [videoModeState, setVideoModeState] = useState({
    isEnabled: false,
    youtubeVideoId: null,
    youtubeVideoLecturer: null,
    videoSubUnitName: null,
  });

  const titleText = [
    ...lectureUnitPath.map((pathPart) => pathPart.name + " " + pathPart.value),
    lectureUnitData.override_parent_name
      ? lectureUnitData.name
      : lectureUnitName + " " + lectureUnitData.name,
  ].join(" - ");

  function resetAll() {
    setVideoModeState({
      isEnabled: false,
      youtubeVideoId: null,
      youtubeVideoLecturer: null,
      videoSubUnitName: null,
    });
  }

  function handleLecturerSubUnitClick(lecturerIndex, subUnitIndex) {
    setVideoModeState({
      isEnabled: true,
      youtubeVideoId:
        lectureUnitData.lecturers[lecturerIndex].sub_units[subUnitIndex]
          .youtube_video_id,
      youtubeVideoLecturer: lectureUnitData.lecturers[lecturerIndex].name,
      videoSubUnitName:
        lectureUnitData.lecturers[lecturerIndex].sub_units[subUnitIndex].name,
    });
  }

  return (
    <>
      <Button className="mt-auto" variant="dark" onClick={() => setShow(true)}>
        הצג שיעורים
      </Button>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        onExited={resetAll}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {videoModeState.isEnabled ? (
          <>
            <Modal.Header className="modal-header-title">
              <Modal.Title id="contained-modal-title-vcenter">
                {[
                  ...lectureUnitPath.map(
                    (pathPart) => pathPart.name + " " + pathPart.value
                  ),
                  lectureUnitData.override_parent_name
                    ? lectureUnitData.name
                    : lectureUnitName + " " + lectureUnitData.name,
                  videoModeState.videoSubUnitName,
                ].join(" - ")}
                <h4 className="fst-italic text-muted">
                  {videoModeState.youtubeVideoLecturer}
                </h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body-video">
              <LectureVideoView
                youtubeEmbedId={videoModeState.youtubeVideoId}
              />
            </Modal.Body>
            <Modal.Footer>
              <Stack
                direction="horizontal"
                gap={4}
                className="col-md-4 mx-auto"
              >
                <Button
                  className="ms-auto"
                  variant="dark"
                  onClick={() =>
                    setVideoModeState({
                      isEnabled: false,
                      youtubeVideoId: null,
                      youtubeVideoLecturer: null,
                      videoSubUnitName: null,
                    })
                  }
                >
                  חזור
                </Button>

                <Button
                  className="me-auto"
                  variant="dark"
                  onClick={() => setShow(false)}
                >
                  סגור
                </Button>
              </Stack>
            </Modal.Footer>
          </>
        ) : (
          <>
            <Modal.Header className="modal-header-title">
              <Modal.Title id="contained-modal-title-vcenter">
                {titleText}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {lectureUnitData.lecturers.map((lecturerData, index) => (
                <div key={index}>
                  {index > 0 ? <hr style={{ border: "1px dashed" }} /> : null}
                  <LecturerUnit
                    lecturerData={lecturerData}
                    onLecturerSubUnitClick={(subUnitIndex) =>
                      handleLecturerSubUnitClick(index, subUnitIndex)
                    }
                  />
                </div>
              ))}
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
              <Button variant="dark" onClick={() => setShow(false)}>
                סגור
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
}

export default LectureUnitModal;
