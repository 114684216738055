import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LecturerSubUnit from "./LecturerSubUnit";
import { Container } from "react-bootstrap";
import Stack from "react-bootstrap/Stack";

function LecturerUnit({ lecturerData, onLecturerSubUnitClick }) {
  return (
    <Row>
      <Col xs={3} lg={2}>
        {lecturerData.name}
      </Col>
      <Col>
        <Container className="show-grid row-no-padding flex-row">
          {lecturerData.sub_units.map(function (subUnitData, index) {
            return (
              <LecturerSubUnit
                lecturerSubUnitData={subUnitData}
                onLecturerSubUnitClick={() => onLecturerSubUnitClick(index)}
              />
            );
          })}
        </Container>
      </Col>
    </Row>
  );
}

export default LecturerUnit;
