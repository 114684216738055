import React from "react";
import BookFilters from "./BookFilters";

function Filters(props) {
  return (
    <div>
      <BookFilters
        filters={props.bookFilters}
        onFilterChange={props.onBookFilterChange}
      />
    </div>
  );
}

export default Filters;
