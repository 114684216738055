import React from "react";
import Button from "react-bootstrap/Button";

function LecturerSubUnit({ lecturerSubUnitData, onLecturerSubUnitClick }) {
  return (
    //<div style={{ border: "1px solid black" }}>{lecturerSubUnitData.name}</div>
    <Button
      style={{ marginTop: "4px", marginLeft: "4px" }}
      variant="outline-dark"
      onClick={onLecturerSubUnitClick}
    >
      {lecturerSubUnitData.name}
    </Button>
  );
}

export default LecturerSubUnit;
