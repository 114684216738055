import NavigationBar from "./NavigationBar";

function Header() {
  return (
    <>
      בס"ד
      <br />
      <br />
      <NavigationBar />
    </>
  );
}

export default Header;
