import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BookFilterUnit from "./BookFilterUnit";

function BookFilters(props) {
  return (
    <Container>
      <Row className="justify-content-start">
        {props.filters.map((bookFilter) => (
          <Col key={bookFilter.id} xs="auto">
            <BookFilterUnit
              id={bookFilter.id}
              name={bookFilter.name}
              selectedValue={bookFilter.selectedValue}
              values={bookFilter.values}
              onFilterChange={(newValue) =>
                props.onFilterChange(bookFilter.id, newValue)
              }
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default BookFilters;
