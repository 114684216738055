import React from "react";

function Footer() {
  return (
    <div className="footer">
      "תָּנָא דְּבֵי אֵלִיָּהוּ: כָּל הַשּׁוֹנֶה הֲלָכוֹת בְּכָל יוֹם, מֻבְטָח
      לוֹ שֶׁהוּא בֶּן הָעוֹלָם הַבָּא..." (נידה עג ע"א)
    </div>
  );
}

export default Footer;
